* {
    text-decoration: none;
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'Raleway-Medium', sans-serif !important;
}

a:hover {
    text-decoration: none !important;
}

ul {
    padding: 0;
    margin: 0;
}

.mobile-bar {
    display: none;
}

.nav-head-mobile {
    display: none;
}

.App > nav {
    position: absolute;
    width: 100%;
    justify-content: space-around;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

nav > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-navigation {
    background-color: transparent;
    box-shadow: none !important;
}

.home-navigation.fixed-top, .site-navigation.fixed-top {
    background-color: white;
    animation: fadeIn .3s ease-in both;
    height: 90px;
}

.site-navigation.fixed-top .navbar-menu.active {
    top: 90px !important;
}

.navbar-menu > .container {
    display: flex;
    justify-content: space-between;
}

.navbar-menu > .container > ul > li:first-child > a {
    padding-left: 0;
}

.home-navigation.fixed-top .link-header {
    color: white;
}


.site-navigation .link-header:hover {
    transition: all .3s ease-in-out;
}

.site-navigation .call-block {
    display: flex;
    align-items: center;
}

.site-navigation .call-block svg {
    font-size: 30px;
    color: #071f9b;
}

.site-navigation .call-block > span {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    color: #071f9b;
}

.site-navigation .call-block > span > span {
    font-size: 14px;
    font-weight: 300;
}

.home-navigation a.call-block {
    color: black !important;
}

.home-navigation .link-header, .home-navigation a.call-block {
    transition: none !important;
}

.navbar-menu ul li {
    position: relative;
}

.navbar-menu ul li:hover > ul.submenu {
    z-index: 99999;
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.navbar-menu ul li > a > svg {
    transition: all .3s;
}

.navbar-menu ul li:hover a svg {
    transform: rotate(180deg);
}

.navbar-menu ul > li:hover > a {
    color: deepskyblue;
}

.home-navigation .logo-to-all, .home-navigation.fixed-top .logo-home, .home-navigation .phone-icon,
.home-navigation.fixed-top .phone-icon-home, .phone-icon-home, .page .logo-home {
    display: none;
}

.home-navigation.fixed-top .logo-to-all, .home-navigation.fixed-top .phone-icon, .home .phone-icon-home {
    display: block;
}

.fixed-top {
    position: fixed !important;
    z-index: 1030 !important;
}

.logo-desc {
    font-size: 20px;
    font-weight: 600;
    font-family: 'ClearSans-Bold', sans-serif;
    padding-left: 15px;
    color: black;
}

.logo:hover {
    color: black;
}

.site-navigation ul {
    display: block;
    position: absolute;
    top: 100%;
}

.topbar {
    padding: 6px;
    display: flex;
    border-bottom: 1px solid #E7EBF0;
}

.topbar .topbar-menu {
    display: flex;
    justify-content: space-between;
}

.topbar .topbar-menu > div {
    display: flex;
}

.lang-menu {
    display: flex;
    margin-bottom: 0;
    padding: 0;
}

.lang-menu li {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 17px;
}

.lang-menu li.active, .lang-menu li:hover {
    color: #2b5ca8;
    font-weight: 600;
}

.search-btn {
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
}

.lang-menu li.active {
    border: 1px solid #2b5ca8;
    border-radius: 5px;
}

.social-block ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 20px;
}

.social-block ul li {
    padding: 5px 8px;
}

.social-block ul svg {
    fill: #2b5ca8;
    font-size: 28px;
}

.social-block ul a:hover svg {
    fill: #071f9b;
}

.change-settings {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    position: relative;
}

.change-settings > span:hover {
    cursor: pointer;
}

.change-settings.active > span {
    color: #2b5ca8;
}

.change-settings span svg {
    fill: #2b5ca8;
}

.change-settings.active > span svg {
    transform: rotate(180deg);
}

.change-settings > span {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.change-settings span svg {
    font-size: 18px;
}

.change-settings span svg:first-child {
    margin-right: 5px;
}

.change-settings span svg:last-child {
    margin-left: 2px;
    transition: all .3s;
}

.change-settings > svg {
    padding-right: 5px;
}

.change-settings > div {
    transform: translateY(50px);
    border: 1px solid #ccc;
    padding: 20px 12px;
    width: 180px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: -1;
    background-color: white;
    transition: all .3s;
}

.change-settings > div.active {
    opacity: 1;
    pointer-events: auto;
    z-index: 9;
    transform: translateY(0px);
}

.change-settings div > div span {
    font-size: 22px;
    font-weight: 500;
}

.change-settings div > div {
    display: flex;
    flex-direction: column;
}

.change-settings div ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    justify-content: space-between;
    margin-bottom: 0;
}

.change-settings div > div:first-child {
    margin-bottom: 20px;
}

.change-settings div > div:first-child ul li {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #000;
    cursor: pointer;
}

.change-settings div > div:first-child ul li:last-child {
    border: 0;
}

.bg-color-changer ul li {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

.bg-color-changer ul li:first-child {
    background-color: cornflowerblue;
}

.bg-color-changer ul li:nth-child(2) {
    background-color: darkgray;
}

.bg-color-changer ul li:last-child {
    background-color: #252525;
}

ul.submenu {
    display: block;
    position: absolute;
    z-index: -1;
    top: 100%;
    left: 0;
    width: 250px;
    background: #fff;
    box-shadow: 0 5px 16px rgb(0 0 0 / 15%);
    transition: all .3s;
    border-radius: 2px;
    opacity: 0;
    transform: translateY(50px);
    pointer-events: none;
    margin: 0;
    padding: 0;
}

ul.submenu li a {
    color: black;
    padding: 10px 16px;
    transition: all .3s;
}

ul.submenu li a:hover {
    background: #efefef;
    color: black !important;
}

.navbar-menu > .container > ul {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: space-around;
    margin-bottom: 0;
    padding-left: 0;
}

.navbar-menu > .container > ul > li {
    height: 50px;
}

.navbar-menu {
    background-color: rgba(240, 242, 247, .97);
}

.nav-head .logo {
    display: flex;
    align-items: center;
    padding-left: 0;
}

.nav-head .logo img {
    width: 240px;
}

#navbar_top {
    padding: 12px 0;
}

.carousel-section {
    width: 100%;
}

.carousel-section .carousel-inner {
    border-radius: 10px;
    height: 560px;
}


.carousel-section .carousel-caption {
    z-index: 99;
    left: 5%;
    right: 5%;
    text-align: left;
    padding-bottom: 10px;
}

.carousel-section .carousel-indicators {
    margin-bottom: 10px;
}

.carousel-section .carousel-control-next,
.carousel-section .carousel-control-prev {
    width: 11%;
}

.carousel-section .carousel-caption h3 {
    margin-bottom: 10px;
    font-size: 22px;
}

.carousel-section .carousel-caption span {
    opacity: .8;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 5px;
}

.carousel-section .carousel-caption span svg {
    width: 14px;
    margin-right: 3px;
    padding-bottom: 1px;
}

.carousel-indicators button {
    height: 1px !important;
    width: 22px !important;
}

.carousel-image {
    height: 560px;
    object-fit: cover;
}

.carousel .slide-gr {
    background: linear-gradient(180deg, rgba(44, 93, 169, 0), #2b5ca8 83.6%);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
}

.main-section {
    display: flex;
    padding: 0px 0px 20px 0px;
}

.search-block {
    position: absolute;
    top: 50px;
    z-index: 9999;
    background-color: white;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0;
    border-bottom: 1px solid #2b5ca8;
    transition: all .3s;
}

#nav_menu {
    position: relative;
}

.search-block .search-input input {
    padding-right: 40px;
}

.search-block > div > span {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
}

.search-block > div > span:last-child {
    font-size: 14px;
    padding-top: 10px;
}

.search-block > div > span:last-child a {
    font-size: 14px;
    text-decoration: none;
    color: black;
    padding-left: 4px;
}

.questions-section > div {
    margin-bottom: 20px;
}

.questions-section > div > a {
    text-transform: uppercase;
    display: flex;
    color: black;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
}

.search-block > div > span .icon-search {
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.search .search-block .container {
    padding: 0;
}

.search .search-block > div > span:last-child {
    display: none;
}

.search .search-block {
    position: relative;
    padding-top: 0;
    top: 0;
}

.header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
}

.header-section h2 {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
}

.questions-section ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.MuiAccordionSummary-root {
    background-color: #2b5ca8 !important;
}

.Mui-expanded:before {
    opacity: 1 !important;
}

.Mui-expanded {
    margin: 10px 0 !important;
}

.MuiAccordionSummary-root p, .MuiAccordionSummary-root svg {
    color: white !important;
}


.questions-section ul li {
    width: 50%;
    padding: 10px 40px 10px 0;
    display: flex;
    align-items: center;
}

.questions-section ul li .qs-icon {
    margin-right: 10px;
    font-size: 50px;
}

.questions-section ul li a {
    text-decoration: none;
    color: black;
}

.companies-section, .page .Map > div:first-child {
    background: linear-gradient(to bottom, #27549a, #4776b3, #1d324d);
    padding: 60px 0;
}

.companies-section .container > h2 {
    text-align: center;
    color: white;
    font-size: 40px;
    margin-bottom: 20px;
}

.news-main {
    padding: 40px 0;
}

.card-news {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    height: 300px;
    margin-bottom: 24px;
}

.card-news::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    background: linear-gradient(180deg, rgba(44, 93, 168, 0), #2b5ca8 60.31%);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.card-news .header-card img {
    object-fit: cover;
    width: 100%;
    height: 220px;
    transition: all .3s;
}

.card-news:hover .header-card img {
    transform: scale(1.2);
}

.card-news .main-card {
    position: absolute;
    z-index: 99;
    width: 100%;
    padding: 20px;
    bottom: 0;
}

.news-link:hover .main-card > span {
    opacity: .9;
}

.card-news .main-card > span {
    color: white;
    text-decoration: none;
}

.card-news .main-card p {
    margin: 0;
    opacity: .7;
    color: white;
}

.card-news .main-card hr {
    color: white;
    border-top: 2px solid;
}

.card-news p > span {
    padding-right: 12px;
    position: relative;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
}

.card-news p > span::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    right: 2px;
    background: #fff;
}

.card-news .main-card p a {
    color: white;
    padding-left: 4px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
}

.marquee-section {
    padding: 20px 0;
}

.marquee a {
    position: relative;
    padding: 0 20px;
}

.marquee a {
    color: #2b343b;
    font-size: 14px;
    text-decoration: none;
}

.marquee a::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 4px;
    bottom: 4px;
    width: 1px;
    background: #cdcdcd;
}

.resources-main {
    padding: 40px 0;
}
.page.faqs .MuiPaper-root > div:first-child{
    padding: 14px 20px;
}

.resources-main .col-lg-3 {
    padding: 20px;
    border: 1px solid #E7EBF0;
    background: white;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.resources-main .col-lg-3 a {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    text-decoration: none;
}

.resources-main .col-lg-3 img {
    width: 30%;
    object-fit: contain;
    padding: 5px 10px 5px 0px;
}

.resources-main .col-lg-3 span {
    display: block;
}

footer {
    padding-top: 10px;
    background: #2b5ca8;
}

footer .col-lg-4 {
    padding-bottom: 20px;
}

footer .col-lg-4 h6 {
    color: white;
    padding-bottom: 10px;
}

footer .col-lg-4 h6 span {
    padding-top: 5px;
    display: block;
}

footer .col-lg-4 h3 {
    font-size: 22px;
    color: white;
    text-transform: uppercase;
    padding: 20px 0;
    max-width: 80%;
    margin: 0;
}

footer .col-lg-4 h2 {
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    padding: 20px 0;
}

footer .col-lg-4:first-child p {
    max-width: 80%;
    color: white;
}

footer .col-lg-12 p {
    border-top: 1px solid #E7EBF0;
    padding: 10px 0;
    margin: 10px 0 0 0;
    color: white;
}

.resources-section, .gallery-section {
    background-color: #f5f5f5;
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
    cursor: e-resize;
}

.gallery-section h2 {
    padding-bottom: 30px;
}

.gallery-section, .resources-section {
    padding-bottom: 30px;
}
.to-login .col-lg-4{
    margin-bottom: 20px;
}
.to-login a{
    text-decoration: none;
    display: flex;
    flex-direction: column-reverse;
}
.to-login .card:hover .card-footer{
    background: rgba(43,92,168,1);
}
.to-login .card-footer{
    background: rgba(43,92,168,.9);
    height: 95px;
}
.to-login .card{
    border-radius: 0;
}
.toLoginImage{
    height: 200px;
    overflow: hidden;
}
.toLoginImage img{
    object-fit: cover;
    object-position: top;
    transition: all .3s;
}
.to-login .card:hover img{
    transform: scale(1.1);
}
.to-login{
    margin-top: 40px;
}
.to-login h2{
    font-size: 22px;
    width: 50%;
}
.to-login h2, .to-login .card span{
    color: white;
}
.to-login-block{
    margin-left: 40px;
    padding-left: 20px;
    border-left: 1px solid #071f9b;
    color: #071f9b;
    text-decoration: none;
}
.to-login-block svg{
    height: 40px;
    width: 40px;
}
.news-filter {
    display: flex;
}

.news-filter {
    border-bottom: 1px solid #ccc;
    margin: 0;
    padding: 0;
}

.news-filter li {
    padding: 10px 0;
    margin-right: 20px;
    text-transform: uppercase;
    cursor: pointer;
    color: #949494;
    font-weight: 600;
    font-size: 17px;
}

.news-filter li:last-child {
    margin-right: 0;
}

.news-filter li.active, .news-filter li:hover {
    border-bottom: 2px solid black;
    color: black;
}
.companies_koj table{
    margin-top: 20px;
}
.companies_koj th th, .companies_koj td td{
    padding-bottom: 0!important;
}
.companies_koj > .container > div > h4, .companies_koj > .container > div > h5{
    max-width: 90%;
    margin: 0 auto 8px auto;
}
.companies_koj > .container > div > h4:first-child{
    margin-top: 20px;
}
.companies_koj > .container > div{
    max-width: 100%;
    overflow-x: scroll;
}
.companies_koj td > div, .companies_koj th{
    text-align: center;
}
.all-news-btn {
    border: 1px solid #2b5ca8;
    padding: 10px 20px;
    text-transform: uppercase;
    max-width: 30%;
    margin: 20px auto;
}

.all-news-btn a {
    text-decoration: none;
    color: #2b5ca8;
    display: flex;
    align-items: center;
    justify-content: center;
}

html.grayscale {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

html.invert body, html.invert body img, html.invert body .change-settings > span, html.invert body .lang-menu, html.invert body .logo-desc,
html.invert body .marquee-section, html.invert body .search-block, html.invert body .questions-section, html.invert body .call-block,
html.invert body .news-section > div:first-child, html.invert body .all-news-btn {
    -webkit-filter: grayscale(100%) invert(100%);
    filter: grayscale(100%) invert(100%);
}


/*Font Sizes*/

body.font-2, body.font-2 ul li, body.font-2 ul li a, body.font-2 a, body.font-2 a span, body.font-2 .date-news,
body.font-2 .breadcrumb-page nav, body.font-2 .page.documents .container, body.font-2 .page.about .col-lg-8, body.font-2
.contact-text p, body.font-2 .page.single .col-lg-9, body.font-2 .page.faqs p, body.font-2 .item-managment p,
body.font-2 .btn-mng button, body.font-2 .MuiModal-root {
    font-size: 107% !important;
}

body.font-2 .company-item p, body.font-2 .companies h3 span, body.font-2 .resources-main span {
    font-size: 16px !important;
}

body.font-2 .carousel-caption span, body.font-2 .company-item h5, body.font-2 footer p, body.font-2 footer h2,
body.font-2 footer h6, body.font-2 .search-block > div > span:last-child {
    font-size: 22px !important;
}

body.font-2 .carousel-caption h3, body.font-2 footer h3 {
    font-size: 28px !important;
}

body.font-2 .header-section h2, body.font-2 .companies > h3 {
    font-size: 34px !important;
}

body.font-2 .companies-section h2 {
    font-size: 45px !important;
}

body.font-3, body.font-3 ul li, body.font-3 ul li a, body.font-3 a, body.font-3 a span, body.font-3 .date-news,
body.font-3 .breadcrumb-page nav, body.font-3 .page.documents .container, body.font-3 .page.about .col-lg-8,
body.font-3 .contact-text p, body.font-3 .page.single .col-lg-9, body.font-3 .page.faqs p, body.font-3 .item-managment p,
body.font-3 .btn-mng button, body.font-3 .MuiModal-root {
    font-size: 114% !important;
}

body.font-3 .company-item p, body.font-3 .resources-main span {
    font-size: 20px !important;
}

body.font-3 .carousel-caption span, body.font-3 .company-item h5, body.font-3 .companies h3 span, body.font-3 footer p,
body.font-3 footer h2, body.font-3 footer h6, body.font-3 .search-block > div > span:last-child {
    font-size: 26px !important;
}

body.font-3 .carousel-caption h3, body.font-3 footer h3 {
    font-size: 33px !important;
}

body.font-3 .header-section h2, body.font-3 .companies > h3 {
    font-size: 40px !important;
}

body.font-3 .companies-section h2 {
    font-size: 50px !important;
}


/*Font Sizes End*/

.text-size ul li {
    cursor: pointer;
}

.text-size ul li:first-child {
    font-size: 18px !important;
}

.text-size ul li:nth-child(2) {
    font-size: 26px !important;
}

.text-size ul li:last-child {
    font-size: 34px !important;
}

.breadcrumb-page {
    padding: 20px 0;
}

.breadcrumb-page a {
    text-decoration: none;
    color: #2b5ca8;
    opacity: .5;
}

.breadcrumb-page a:hover {
    text-decoration: underline !important;
}

.breadcrumb-page ol li:last-child a {
    opacity: 1;
    pointer-events: none;
}

.page {
    padding-bottom: 40px;
}

.page img {
    width: 100%;
    object-fit: contain;
}

.list-about-us {
    padding: 0;
    margin: 0;
}

.list-about-us li {
    background-color: #E7EBF0;
    margin-bottom: 1px;
    font-size: 16px;
    color: #2b5ca8;
    transition: all .3s;
}

.list-about-us li a {
    color: #2b5ca8;
    height: 100%;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-about-us li svg {
    width: 15px;
    height: 15px;
    transition: all .5s;
}

.list-about-us li.active svg, .list-about-us li:hover svg {
    transform: rotate(90deg);
}

.list-about-us li.active, .list-about-us li:hover {
    background-color: #2b5ca8;
    color: white;
}

.list-about-us li.active a, .list-about-us li:hover a {
    color: white;
}

.list-about-us li a {
    text-decoration: none;
}

.list-about-us.sticky-top {
    top: 55px;
}

.item-managment {
    padding: 20px;
    margin: 10px 0 20px 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgb(40 41 61 / 4%), 0 8px 16px rgb(96 97 112 / 16%);
    display: flex;
    flex-wrap: wrap;
}

.item-managment .mng-img {
    border-radius: 2px;
}

.item-managment p {
    padding-left: 30px;
    margin-bottom: 20px;
    font-size: 16px;
}

.item-managment p a {
    color: black;
    text-decoration: none;
}

.item-managment .mng-position {
    color: #949494;
}

.item-managment .mng-fullname {
    color: #2b5ca8;
    font-weight: 600;
    font-size: 20px;
    margin-top: -18px;
}

.item-managment p span {
    color: #949494;
    padding-right: 10px;
}

.page h2 {
    margin-bottom: 30px;
}

.item-managment .col-lg-6 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.btn-mng {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn-mng button {
    margin: 10px;
}

.page .Map {
    width: 100%;
    flex-direction: column;
}

.page .Map > div:first-child {
    width: 100% !important;
    padding: 30px;
}
.page .companies .company-item{
    width: 50%;
}
.page .companies {
    margin-left: 0;
    width: 100% !important;
    padding-left: 0;
}

.page .companies > div {
    max-height: 100%;
}

.companies .more {
    position: relative;
    bottom: -10px;
}

.companies .more a {
    display: flex;
    align-items: center;
    color: black;
}

.news-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.news-header h2 {
    margin-bottom: 0;
}

.news-header li a {
    text-decoration: none;
    color: #949494;
    padding: 10px 0;
    display: block;
    height: 100%;
}

.news-header li {
    padding: 0;
}

.news-header li:hover a, .news-header li.active a {
    color: black;
}

.dis-link {
    cursor: alias;
    pointer-events: none;
}

.pagination-news ul {
    display: flex;
    margin: 0 auto;
    padding: 30px 0 10px;
    justify-content: center;
    align-items: center;
}

.pagination-news ul li {
    margin: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-news ul li a {
    text-decoration: none;
    color: #2b5ca8 !important;
    height: 100%;
    padding: 2px 10px 4px;
    transition: all .3s;
    border-radius: 8px;
    display: block;
    border: 1px solid #2b5ca8;
}

.pagination-news .selected a, .pagination-news ul li a:hover {
    background-color: #2b5ca8;
    color: white !important;
}

.gallery-block {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
}

.gallery-block .row {
    width: 100%;
}

.gallery-block a {
    display: block;
    height: 100%;
    text-decoration: none;
    position: relative;
}

.gallery-block a img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.gallery-block .col-lg-6, .single-gallery .col-lg-3 {
    padding-bottom: 24px;
}

.gallery-block a p {
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    flex-direction: column;
    font-size: 18px;
    position: absolute;
    z-index: 9;
    padding: 14px;
    bottom: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

.gallery-block p span {
    position: relative;
    z-index: 9;
}

.gallery-block .date-gallery {
    font-size: 14px;
}
.col-lg-6 .card-news{
    height: 400px;
}
.col-lg-6 .card-news:after{
    background: linear-gradient(180deg, rgba(44, 93, 168, 0), #2b5ca8 80.31%);
}
.col-lg-6 .card-news .header-card img{
    height: 320px;
}
.gallery-block .col-lg-6 a p:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    background: linear-gradient(180deg, rgba(44, 93, 168, 0), #2b5ca8 85.31%);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.gallery-block .play-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-block .play-btn svg {
    width: 140px;
    height: 140px;
    fill: white;
    z-index: 9;
    transition: all .3s;
}

.gallery-block .col-lg-6:hover svg {
    width: 170px;
    height: 170px;
}

.video-modal {
    overflow: inherit !important;
}

.close-video {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    background-color: white;
    color: #2b5ca8;
    display: block;
    cursor: pointer;
}

.close-video svg {
    transition: all .3s;
    width: 30px;
    height: 30px;
}

.close-video:hover svg {
    transform: rotate(180deg);
}

.page .table tbody td:first-child {
    width: fit-content;
}

.page .table thead {
    color: #fff;
    background: #2b5ca8;
}

.page thead th {
    font-weight: 500;
    padding: 16px 12px;
    vertical-align: middle;
}

.page tbody td, .page tbody th {
    padding: 12px;
    vertical-align: middle;
}

.page tbody tr {
    border: white;
}

.page tbody td a {
    display: flex;
    align-items: center;
    width: 100px;
    color: #2b5ca8;
    text-decoration: none;
}

.page tbody td a svg {
    margin-right: 5px;
}

.contact-text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 20px 0;
}

.contact-text p {
    color: black;
    opacity: .7;
    font-size: 16px;
    margin: 0;
    padding-left: 12px;
}

.contact-text p span {
    display: block;
    font-size: 20px;
}

.contact-text svg {
    fill: #2b5ca8;
}

.form-contact-title {
    text-align: center;
    margin: 20px 0;
}

.form-block form {
    display: flex;
    flex-direction: column;
}

.form-block form > div {
    margin: 10px 0;
}

.date-news-inner {
    display: block;
    padding: 10px 0;
}

.date-news-inner svg {
    margin-right: 4px;
}

.page .inner-post-image {
    height: 500px;
    object-fit: cover;
    width: 100%;
}

.page .inner-news-title {
    margin-bottom: 20px;
}

.last-news h3 {
    color: #2b5ca8;
}

.last-news-list {
    margin: 0;
    padding: 0;
}

.last-news-list li {
    padding: 12px 0;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 2px;
    margin-bottom: 2px;
}

.last-news.sticky-top {
    top: 55px;
}

.last-news {
    padding: 15px;
    border: 1px solid #2b5ca8;
}

.last-news-list li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.last-news-list li:last-child:after {
    content: none;
}

.last-news-list li:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    bottom: -2px;
    text-decoration: dotted;
    border: 2px dotted #2b5ca8;
}

.last-news-list li a {
    text-decoration: none;
    color: black;
}

.about-img {
    margin-bottom: 20px;
}

.search-results {
    margin: 0;
    padding: 0 20px;
}

.search .search-block {
    border-bottom: 0px;
    padding-bottom: 20px;
    z-index: 1;
}

.search-results.notFound {
    padding: 0;
}

.search-results li.notFound {
    background-color: #E7EBF0;
    color: black;
    list-style: none;
    font-size: 24px;
    padding: 20px;
    border-radius: 10px;
}

.search-results li {
    padding: 15px 15px 15px 0px;
    list-style: circle;
}

.search-results li a {
    color: #2b5ca8;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
}
.mobile-block{
    display: none;
}
#nav_menu .lang-menu, #nav_menu .mobile-block, #nav_menu .phone-btn{
    display: none;
}
.mngModal .close-video{
    padding: 10px;
    background-color: transparent;
}
#SRLLightbox, .MuiModal-root{
    z-index: 999999!important;
}


@media (min-width: 1000px) and (max-width: 1200px){
    ul li .link-header{
        font-size: 14px;
    }
}
@media (min-width: 768px) and (max-width: 1000px){
    .topbar, #navbar_top, #nav_menu, .fixed-title-map {
        display: none;
    }
    #nav_menu .lang-menu, #nav_menu .search-block, #nav_menu .phone-btn{
        display: flex;
    }
    .news-section .header-section > div {
        width: 100%;
    }

    .all-news-btn {
        max-width: 90%;
    }

    .news-filter li {
        font-size: 14px;
        display: flex;
        align-items: flex-end;
    }

    .header-section {
        flex-wrap: wrap;
        padding-top: 20px;
    }
    #nav_menu.active{
        display: block;
        position: fixed;
        top: 0px;
        padding: 90px 0 20px 0;
        width: 100%;
        z-index: 9999;
        height: 100%;
        overflow: auto;
    }
    .Map .companies{
        border: 1px solid #2b5ca8;
        padding: 10px;
    }
    .news-header h2{
        margin-bottom: 5px;
    }
    .contact-text p span{
        font-size: 18px;
    }
    .news-header{
        flex-direction: column;
    }
    .MuiModal-root .MuiBox-root{
        width: 90%;
        padding: 16px;
    }
    .btn-mng{
        width: 100%;
        flex-direction: row;
    }
    .page.news .col-lg-3, .gallery-block .col-lg-6, .btn-mng button{
        width: 50%;
    }
    .item-managment{
        padding: 20px;
    }
    .item-managment .col-lg-3:first-child{
        width: 25%;
    }
    .item-managment .mng-img{
        margin-bottom: 10px;
    }
    .item-managment p{
        padding-left: 0;
    }
    .page .inner-post-image{
        height: 250px;
    }
    .active.navbar-menu > .container > ul{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 0;
    }
    #nav_menu.active .container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .navbar-menu > .container > ul > li:first-child > a{
        padding: 0;
        display: flex;
    }
    ul.submenu{
        width: 100%;
        transition: all 0s!important;
    }
    .navbar-menu ul li.active > ul.submenu{
        position: relative;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #fff;
        pointer-events: auto!important;
        z-index: 9999!important;
        opacity: 1!important;
        transform: translateY(0) !important;
        margin-top: 20px;
    }

    .navbar-menu > .container > ul > li.active ul li a{
        padding: 10px 0;
        justify-content: center;
    }
    .navbar-menu ul li a{
        justify-content: space-between;
        padding: 0;
    }
    #root{
        padding-top: 70px;
    }
    .navbar-menu ul li a svg{
        pointer-events: none;
    }
    .search-btn{
        display: none;
    }
    .navbar-menu ul > li.active > a{
        color: deepskyblue!important;
    }
    .navbar-menu ul li.active a svg{
        transform: rotate(180deg) !important;
    }
    .navbar-menu ul > li:hover > a{
        color: white;
    }
    .navbar-menu ul li:hover a svg{
        transform: none;
    }
    .navbar-menu ul li:hover > ul.submenu{
        pointer-events: none;
        z-index: -1;
        opacity: 0;
    }
    ul.submenu li a{
        color: white;
        padding: 10px 0;
        display: flex;
        justify-content: center;
    }
    .navbar-menu > .container > ul > li{
        height: 100%;
        padding: 15px 0;
        width: 90%;
    }
    .mobile-navbar-menu {
        width: 20%;
    }
    ul.submenu li a:hover{
        background-color: transparent;
        color: white!important;
    }
    .active.navbar-menu > .container > ul.lang-menu{
        display: flex;
        flex-direction: row;
        border-top: 1px solid #fff;
        padding: 10px 0;
        border-bottom: 1px solid #fff;
        margin-bottom: 10px;
    }
    .active.navbar-menu > .container > ul.lang-menu li.active{
        border: 2px solid #fff;
    }
    .active.navbar-menu > .container > ul.lang-menu li {
        color: white;
        padding: 5px;
    }
    #nav_menu .search-block {
        position: relative;
        background: transparent;
        top: 0;
        padding: 0;
        color: #2b5ca8;
    }
    #nav_menu .search-input{
        background: transparent;
    }
    #nav_menu .search-block .Mui-focused span{
        opacity: 0;
    }
    #nav_menu .search-block .Mui-focused{
        color: #000;
    }
    #nav_menu .search-block .container{
        padding: 0;
        margin: 0;
    }
    .mobile-block, .search-btn.mobile-block{
        display: block;
    }
    .mobile-block.phone-btn{
        width: 100%;
        padding: 0px 20px;
    }
    .mobile-block.phone-btn a svg{
        margin-right: 10px;
    }
    .mobile-block.phone-btn a > span{
        display: flex;
        flex-direction: column-reverse;
    }
    .mobile-block.phone-btn a{
        padding: 0px 20px;
        color: white;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        width: 100%;
        text-align: center;
        padding: 10px 0;
    }
    #nav_menu .search-input > div{
        background-color: white;
    }
    #nav_menu .search-block span input{
        color: #2b5ca8;
    }
    #nav_menu .search-block > div > span:last-child{
        display: none;
    }
    .nav-head-mobile {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        background-color: #2b5ca8;
        align-items: center;
        position: fixed;
        top: 0;
        height: 70px;
        width: 100%;
        z-index: 999999;
    }

    .nav-head-mobile .logo {
        padding: 0;
        width: 80%;
        padding-left: 10px;
    }

    .nav-head-mobile .logo img {
        width: 40%;
        height: 90%;
        object-fit: contain;
    }

    .nav-head-mobile .logo span {
        font-size: 14px;
        line-height: 14px;
        color: #E7EBF0;
        font-weight: 500;
        padding-left: 5px;
    }

    .mobile-bar {
        border-radius: 15px;
        position: relative;
        display: flex;
        background: none;
        height: 58px;
        min-width: 58px;
        align-items: center;
        justify-content: center;
        border: none;
    }

    .marquee a {
        font-size: 13px;
    }

    .carousel-section {
        margin-right: 0;
    }

    .carousel-section .carousel-inner, .carousel-image {
        height: 400px;
    }

    .carousel-section .carousel-caption span {
        font-size: 12px;
    }

    .carousel-section .carousel-caption h3 {
        font-size: 15px;
    }

    .header-section h2, .companies-section .container > h2, .companies h3 {
        font-size: 22px;
    }

    .companies-section {
        padding: 20px 0;
    }

    .Map {
        flex-wrap: wrap;
    }

    .Map > div:first-child, .Map .companies {
        width: 100% !important;
    }

    .Map .companies {
        margin-left: 0;
    }

    .companies .company-item {
        width: 100%;
    }

    .questions-section .header-section {
        margin-bottom: 20px;
    }

    .questions-section .header-section a {
        display: none;
    }

    .questions-section ul li {
        width: 50%;
        padding: 10px 0;
        align-items: flex-start;
    }

    .questions-section ul li .qs-icon {
        font-size: 30px;
    }

    /* Hamburger menu */
    .m-hamburger::before, .m-hamburger::after {
        transition-property: transform;
    }

    .m-hamburger::before, .m-hamburger::after, .m-hamburger__label {
        transition-timing-function: ease;
        transition-duration: .5s;
    }

    .menu_activated .m-hamburger::before {
        top: 50%;
        transform: translate3d(0, -50%, 0) rotate(45deg);
    }

    .m-hamburger::before {
        top: 0;
    }

    .m-hamburger::before, .m-hamburger::after {
        content: "";
    }

    .m-hamburger::before, .m-hamburger::after, .m-hamburger__label {
        width: 100%;
        height: 2px;
        border-radius: 5px;
        background-color: #fff;
        position: absolute;
        left: 0;
    }

    .menu_activated .m-hamburger::after {
        transform: translate3d(0, -50%, 0) rotate(135deg);
        top: 50%;
    }

    .m-hamburger::after {
        bottom: 0;
    }

    .menu_activated .m-hamburger__label {
        transform: rotate(-45deg) translate3d(-0.285em, -0.3em, 0);
        opacity: 0;
    }

    .m-hamburger__label {
        transition-property: transform, opacity;
    }

    .m-hamburger {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-mobile-menu {
        position: relative;
        width: 30px;
        height: 23px;
    }

    /* END Hamburger menu */
    .page .table > tbody > tr > td:before {
        content: attr(aria-label);
        width: 40%;
        float: left;
        text-align: right;
        word-wrap: break-word;
        font-weight: 700;
        font-style: normal;
        padding: 0 0.8rem 0 0;
        margin: 0;
        font-size: 14px;
    }

    .page .table tbody td:first-child {
        width: auto;
    }

    .table > tbody, .table > tbody > tr {
        display: block;
    }

    .table > tbody > tr > td {
        display: flex;
        align-items: center;
    }

    .table > tbody > tr > td > div {
        width: 60%;
        font-size: 14px;
    }

    .table thead {
        display: none;
    }
    .news-main .col-lg-3, .resources-main .col-lg-3{
        width: 50%;
    }
}
@media only screen and (max-width: 768px) {
    .col-lg-6 .card-news{
        height: 300px;
    }
    .topbar, #navbar_top, #nav_menu, .fixed-title-map {
        display: none;
    }
    #nav_menu .lang-menu, #nav_menu .search-block, #nav_menu .phone-btn{
        display: flex;
    }
    .news-section .header-section > div {
        width: 100%;
    }

    .all-news-btn {
        max-width: 90%;
    }

    .news-filter li {
        font-size: 14px;
        display: flex;
        align-items: flex-end;
    }

    .header-section {
        flex-wrap: wrap;
        padding-top: 20px;
    }
    #nav_menu.active{
        display: block;
        position: fixed;
        top: 0px;
        padding: 90px 0 20px 0;
        width: 100%;
        z-index: 9999;
        height: 100%;
        overflow: auto;
    }
    .Map .companies{
        border: 1px solid #2b5ca8;
        padding: 10px;
    }
    .news-header h2{
        margin-bottom: 5px;
    }
    .contact-text p span{
        font-size: 18px;
    }
    .news-header{
        flex-direction: column;
    }
    .MuiModal-root .MuiBox-root{
        width: 90%;
        padding: 16px;
    }
    .btn-mng{
        width: 100%;
    }
    .item-managment{
        padding: 10px;
    }

    .item-managment .mng-img{
        margin-bottom: 10px;
    }
    .item-managment p{
        padding-left: 0;
    }
    .page .inner-post-image{
        height: 250px;
    }
    .active.navbar-menu > .container > ul{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 0;
    }
    #nav_menu.active .container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .navbar-menu > .container > ul > li:first-child > a{
        padding: 0;
        display: flex;
    }
    ul.submenu{
        width: 100%;
        transition: all 0s!important;
    }
    .navbar-menu ul li.active > ul.submenu{
        position: relative;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #fff;
        pointer-events: auto!important;
        z-index: 9999!important;
        opacity: 1!important;
        transform: translateY(0) !important;
        margin-top: 20px;
    }

    .navbar-menu > .container > ul > li.active ul li a{
        padding: 10px 0;
        justify-content: center;
    }
    .navbar-menu ul li a{
        justify-content: space-between;
        padding: 0;
    }
    #root{
        padding-top: 70px;
    }
    .navbar-menu ul li a svg{
        pointer-events: none;
    }
    .search-btn{
        display: none;
    }
    .navbar-menu ul > li.active > a{
        color: deepskyblue!important;
    }
    .navbar-menu ul li.active a svg{
        transform: rotate(180deg) !important;
    }
    .navbar-menu ul > li:hover > a{
        color: white;
    }
    .navbar-menu ul li:hover a svg{
        transform: none;
    }
    .navbar-menu ul li:hover > ul.submenu{
        pointer-events: none;
        z-index: -1;
        opacity: 0;
    }
    ul.submenu li a{
        color: white;
        padding: 10px 0;
        display: flex;
        justify-content: center;
    }
    .navbar-menu > .container > ul > li{
        height: 100%;
        padding: 15px 0;
        width: 90%;
    }
    .mobile-navbar-menu {
        width: 20%;
    }
    ul.submenu li a:hover{
        background-color: transparent;
        color: white!important;
    }
    .active.navbar-menu > .container > ul.lang-menu{
        display: flex;
        flex-direction: row;
        border-top: 1px solid #fff;
        padding: 10px 0;
        border-bottom: 1px solid #fff;
        margin-bottom: 10px;
    }
    .active.navbar-menu > .container > ul.lang-menu li.active{
        border: 2px solid #fff;
    }
    .active.navbar-menu > .container > ul.lang-menu li {
        color: white;
        padding: 5px;
    }
    #nav_menu .search-block {
        position: relative;
        background: transparent;
        top: 0;
        padding: 0;
        color: #2b5ca8;
    }
    #nav_menu .search-input{
        background: transparent;
    }
    #nav_menu .search-block .Mui-focused span{
        opacity: 0;
    }
    #nav_menu .search-block .Mui-focused{
        color: #000;
    }
    #nav_menu .search-block .container{
        padding: 0;
        margin: 0;
    }
    .mobile-block, .search-btn.mobile-block{
        display: block;
    }
    .mobile-block.phone-btn{
        width: 100%;
        padding: 0px 20px;
    }
    .mobile-block.phone-btn a svg{
        margin-right: 10px;
    }
    .mobile-block.phone-btn a > span{
        display: flex;
        flex-direction: column-reverse;
    }
    .mobile-block.phone-btn a{
        padding: 0px 20px;
        color: white;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        width: 100%;
        text-align: center;
        padding: 10px 0;
    }
    #nav_menu .search-input > div{
        background-color: white;
    }
    #nav_menu .search-block span input{
        color: #2b5ca8;
    }
    #nav_menu .search-block > div > span:last-child{
        display: none;
    }
    .nav-head-mobile {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        background-color: #2b5ca8;
        align-items: center;
        position: fixed;
        top: 0;
        height: 70px;
        width: 100%;
        z-index: 999999;
    }

    .nav-head-mobile .logo {
        padding: 0;
        width: 80%;
        padding-left: 10px;
    }

    .nav-head-mobile .logo img {
        width: 40%;
        height: 90%;
        object-fit: contain;
    }

    .nav-head-mobile .logo span {
        font-size: 14px;
        line-height: 14px;
        color: #E7EBF0;
        font-weight: 500;
        padding-left: 5px;
    }

    .mobile-bar {
        border-radius: 15px;
        position: relative;
        display: flex;
        background: none;
        height: 58px;
        min-width: 58px;
        align-items: center;
        justify-content: center;
        border: none;
    }

    .marquee a {
        font-size: 13px;
    }

    .carousel-section {
        margin-right: 0;
    }

    .carousel-section .carousel-inner, .carousel-image {
        height: 260px;
    }

    .carousel-section .carousel-caption span {
        font-size: 12px;
    }

    .carousel-section .carousel-caption h3 {
        font-size: 15px;
    }

    .header-section h2, .companies-section .container > h2, .companies h3 {
        font-size: 22px;
    }

    .companies-section {
        padding: 20px 0;
    }

    .Map {
        flex-wrap: wrap;
    }

    .Map > div:first-child, .Map .companies {
        width: 100% !important;
    }

    .Map .companies {
        margin-left: 0;
    }

    .companies .company-item {
        width: 100%;
    }

    .questions-section .header-section {
        margin-bottom: 20px;
    }

    .questions-section .header-section a {
        display: none;
    }

    .questions-section ul li {
        width: 100%;
        padding: 10px 0;
        align-items: flex-start;
    }

    .questions-section ul li .qs-icon {
        font-size: 30px;
    }

    /* Hamburger menu */
    .m-hamburger::before, .m-hamburger::after {
        transition-property: transform;
    }

    .m-hamburger::before, .m-hamburger::after, .m-hamburger__label {
        transition-timing-function: ease;
        transition-duration: .5s;
    }

    .menu_activated .m-hamburger::before {
        top: 50%;
        transform: translate3d(0, -50%, 0) rotate(45deg);
    }

    .m-hamburger::before {
        top: 0;
    }

    .m-hamburger::before, .m-hamburger::after {
        content: "";
    }

    .m-hamburger::before, .m-hamburger::after, .m-hamburger__label {
        width: 100%;
        height: 2px;
        border-radius: 5px;
        background-color: #fff;
        position: absolute;
        left: 0;
    }

    .menu_activated .m-hamburger::after {
        transform: translate3d(0, -50%, 0) rotate(135deg);
        top: 50%;
    }

    .m-hamburger::after {
        bottom: 0;
    }

    .menu_activated .m-hamburger__label {
        transform: rotate(-45deg) translate3d(-0.285em, -0.3em, 0);
        opacity: 0;
    }

    .m-hamburger__label {
        transition-property: transform, opacity;
    }

    .m-hamburger {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-mobile-menu {
        position: relative;
        width: 30px;
        height: 23px;
    }

    /* END Hamburger menu */
    .page .table > tbody > tr > td:before {
        content: attr(aria-label);
        width: 40%;
        float: left;
        text-align: right;
        word-wrap: break-word;
        font-weight: 700;
        font-style: normal;
        padding: 0 0.8rem 0 0;
        margin: 0;
        font-size: 14px;
    }

    .page .table tbody td:first-child {
        width: auto;
    }

    .table > tbody, .table > tbody > tr {
        display: block;
    }

    .table > tbody > tr > td {
        display: flex;
        align-items: center;
    }

    .table > tbody > tr > td > div {
        width: 60%;
        font-size: 14px;
    }

    .table thead {
        display: none;
    }
}

