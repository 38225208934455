.fixed-title-map{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -500px;
    top: -500px;
}
.fixed-title-map span{
    display: flex;
    font-weight: 600;
    background-color: rgba(0,0,0,0.8);
    padding: 2px 10px 5px;
    color: white;
}
.companies{
    margin: 20px 0px 20px 30px;
    padding: 25px 30px;
    background-color: white;
}
.Map{
    display: flex;
    flex-wrap: nowrap;
}
.Map > div:first-child{
    display: flex;
    align-items: center;
}
.companies h3{
    font-size: 32px;
}
.companies h3 span{
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding-top: 5px;
    margin-top: 5px;
    border-top: 1px solid orange;
}
.companies > div{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -15px;
    max-height: 410px;
    overflow: auto;
}
.companies > div::-webkit-scrollbar{
    width: 4px;
}
::-webkit-scrollbar-thumb {
    background: #4776b3;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: darkorange;
}
.companies > div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.5);
    border-radius: 10px;
}
.companies .company-item{
    width: 100%;
    padding: 15px 15px 5px 15px;
}
.companies .company-item h5{
    font-size: 18px;
    font-weight: 600;
}
.companies .company-item p{
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 0;
}
.svg-map {
    width: 100%;
    height: auto;
    stroke: #666;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round; }
.svg-map__location {
    fill: whitesmoke;
    cursor: pointer; }
.svg-map__location:focus, .svg-map__location:hover {
    fill: #f4bc44;
    outline: 0; }
.svg-map__location[aria-checked=true] {
    fill: #f4bc44; }

