@font-face {
    font-family: "Raleway-Regular";
    src: local("Raleway-Regular"),
    url("./fonts/static/Raleway-Regular.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "Raleway-SemiBold";
    src: local("Raleway-SemiBold"),
    url("./fonts/static/Raleway-SemiBold.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: "Raleway-Medium";
    src: local("Raleway-Medium"),
    url("./fonts/static/Raleway-Medium.ttf") format("truetype");
    font-weight: 500;
}
